.exam-page {
    padding: 0 7px;

    .modal-video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        z-index: 1000;
        cursor: pointer;
        opacity: 1;
        animation-timing-function: ease-out;
        animation-duration: .3s;
        animation-name: modal-video;
        -webkit-transition: opacity .3s ease-out;
        -moz-transition: opacity .3s ease-out;
        -ms-transition: opacity .3s ease-out;
        -o-transition: opacity .3s ease-out;
        transition: opacity .3s ease-out;
        .modal-video-body {
            max-width: 960px;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            .modal-video-inner {
                display: flex;
                justify-content: center;
                align-items: center;
                .modal-video-movie-wrap {                   
                    position: relative;
                    padding-top: 5%;
                    padding-bottom: 5% !important;
                    display: flex;
                    .modal-video-close-btn {
                     
                        position: relative;
                        z-index: 10000;
                        left: 25px;
                        bottom: 10px;
                        display: inline-block;
                        width: 25px;
                        height: 25px;
                        border: black;
                        background-image: url(https://image.flaticon.com/icons/png/512/463/463612.png);
                        background-position: center;
                        background-size: contain;
                        background-color: transparent;
                        margin-bottom: 10px;
                    }
                    button.modal-video-close-btn:hover {
                        cursor: pointer;
                    }
                    
                    .modal-video-close-btn:before, .modal-video-close-btn:after
                    {
                        background-color: white;
                    }
                    iframe{
                        width: 768px;
                        height: 432px;
                        margin-top: 25px;
                    }
                }
            }
        }
    }
}