.register-container {
    max-width: 1100px;
    margin: 0 auto;
    @include for-size(md) {
        display: flex;
    }
    .register-container-left {
        flex: 1;
        font-weight: 700;
        color: #2e9dea;
        font-size: 1.3rem;
        margin-top: 16px;
    }
    .register-container-right {
        width: 100%;
        @include for-size(md) {
            min-width: 650px;
        }
        flex: 2;
        background-color: #f2f2f2;
        .register-exclamation {
            background: none;
            border-color: red;
            border-radius: 6px;
            margin-top: 1em;
            margin-bottom: 1em;
            font-size: 1em;
            border-style: solid;
            border-width: 1px;
            display: block;
            font-weight: 300;
            padding: 16px 20px;
            position: relative;
            width: calc(100% - 16px);
            margin: 0 auto;
            margin-bottom: 24px;
            @include for-size(lg) {
                width: 100%;
            }
            legend {
                width: fit-content !important;
                position: absolute !important;
                top: -16px;
                left: -8px;
                background-color: rgb(240, 242, 245);
                margin: 0px;
                padding: 2px;
            }
        }
    
        .container-content {
            border: #ccc 1px solid;
            border-radius: 2px;
            padding: 16px;
            .page-title {
                margin-top: 16px;
                font-weight: bolder;
                font-size: 1.5rem;
            }
            .register-content {
                padding: 0px;
                @include for-size(md) {
                    padding: 28px;
                }
                .ant-form-item-label {
                    font-weight: bold;
                    text-align: left;
                    label {
                        font-size: 16px !important;
                    }
                }
                .ant-col-offset-2 {
                    margin-left: 0;
                    @include for-size(md) {
                        margin-left: 8.33333333%;
                    }
                }
            }
        }
    }
}