.teacher-surveys-page {
    display: flex;
    flex-direction: column;
    .wrapper {
        flex: 1;
        iframe {
            position: absolute;
            right: 9.5%;
            width: 77%;
            transform: scale(1.25);
            transform-origin: top;
        }
    }
}