.competences-page {
    padding: 16px 8px;

    .competences-container {
        & > .ant-space {
            max-width: 100%;
            overflow: hidden;
        }
        .ant-tabs-content-holder {
            margin-top: -16px;
        }
        .competences-tab-content {
            background-color: white;
            // Perform Test
            .performed-test-container {
                .title {
                    font-size: 24px;
                    font-weight: bold;
                    margin: 0 auto;
                    margin-bottom: 24px;
                    margin-top: 16px;
                    max-width: 1200px;
                }

                .ant-table-thead > tr > th {
                    font-weight: bold;
                }

                .imagePath {
                    width: 15vw;
                    height: auto;
                    max-width: 80px;
                    max-height: 80px;
                    @include for-size(md){
                        padding: 16px;
                    }
                }
                .base-table {
                    font-weight: 500;
                    margin: 0 auto;
                    max-width: 1200px;
                }
    
                .competence-name {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                }
    
                .imagePath--detail {
                    margin-right: 8px;
                }
    
                .btn-back {
                    text-align: center;
                    margin: 8px 0;
                    button {
                        height: 40px;
                        width: 100px;
                        span {
                            font-size: 16px;
                        }
                    }
                }
    
                .info-performed-test {
                    background-color: #ccc;
                    margin: 0 auto;
                    margin-bottom: 24px;
                    padding: 1em 16px;
                    max-width: 1200px;
                    @include for-size(lg) {
                        display: flex;
                        padding: 16px 15vw;
                    }
                    @include for-size(xxl) {
                        display: flex;
                        padding: 16px 10%;
                    }
                    .create-date, .status {
                        flex: 1;
                    }
                    .status {
                        @include for-size(lg) {
                            text-align: right;
                        }
                    }
                }

                .score, .competence-name {
                    padding: 16px 0;
                    margin: 0 auto;
                    font-weight: normal;
                }

                .max-score {
                    margin: 0 auto;
                }
    
                strong {
                    min-width: 100px;
                    font-weight: bold;
                    display: inline-block;
                    @include for-size(lg) {
                        text-align: left;
                        display: inline;
                    }
                }
                .ant-spin-spinning {
                    display: block;
                    padding: 20vh;
                }
            }

            // Digital Profile
            // Competences Profile
            .survey-profile-container {

                .title {
                    font-size: 24px;
                    font-weight: bold;
                    margin: 0 auto;
                    margin-bottom: 24px;
                    margin-top: 16px;
                    max-width: 1200px;
                }
                .grid {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    flex-wrap: wrap;
                    max-width: 1200px;
                    margin: 0 auto;
                    @include for-size(md) {
                        justify-content: start;
                    }
                    .card {
                        margin: 32px 0;
                        width: 100%;
                        min-width: 280px;
                        min-height: 300px;
                        font-size: 16px;
                        cursor: pointer;
                        margin-top: 16px;
                        @include for-size(sm) {
                            width: 75%;
                        }
                        @include for-size(md) {
                            width: 30%;
                            min-width: 280px;
                            margin: 32px;
                        }
                        img {
                            width: 110px;
                            display: block;
                            margin: 0 auto;
                        }
                        .ant-card-meta-title {
                            font-weight: bold;
                            font-size: 20px;
                            white-space: normal;
                            text-overflow: unset;
                        }
                    }
                }
                .ant-pagination {
                    text-align: right;
                    max-width: 1200px;
                    margin-bottom: 16px;
                }
                .ant-spin-spinning {
                    display: block;
                    padding: 20vh;
                }
                .about-competence {
                    display: flex;
                    margin: 0 auto;
                    max-width: 1200px;
                    margin-bottom: 24px;
                    .col-1 {
                        text-align: center;
                        min-width: 120px;
                        .badge {
                            width: 25vw;
                            height: auto;
                            max-width: 80px;
                            padding: 4px;
                        }

                        .gray-out {
                            filter: grayscale(100%);
                        }

                        .achieved {
                            color: red;
                        }
                    }

                    .col-2 {
                        padding: 4px 8px;
                        padding-right: 4px;
                    }
                }

                .ant-table-thead > tr > th {
                    font-weight: bold;
                }

                .area {
                    display: flex;
                    align-items: center;
                    .imagePath {
                        width: 15vw;
                        height: auto;
                        max-width: 80px;
                        max-height: 80px;
                        padding: 4px;
                        @include for-size(sm){
                            padding: 12px;
                        }
                    }
                }

                .base-table {
                    font-weight: 500;
                    margin: 0 auto;
                    max-width: 1200px;
                    background-color: #fafafa;
                    margin-bottom: 8px;
                    @include for-size(md) {
                        padding: 0 16px 16px;
                    }
                    table {
                        height: fit-content;
                        background-color: #fff;
                        * {
                            box-sizing: border-box;
                        }

                        td.work-field {
                            vertical-align: top;
                        }
                    }
                }

                .title-th {
                    display: flex;
                    .title-score {
                        width: 100px;
                        text-align: center;
                    }
                    .title-competence {
                        flex: 1;
                    }
                }
                .title-for-print {
                    text-align: left;
                    padding: 16px;
                    color: #fff;
                    font-size: 20px;
                    margin: 0 auto;
                    background-color: rgb(31, 78, 121);
                    margin-bottom: 16px;
                    max-width: 1200px;
                    min-width: 800px;
                }

                td.col-competences {
                    background-color: inherit;
                    .competences {
                        .ant-table {
                            margin: 0 !important;
                        }
                    }

                }

                .competence, .score, td.work-field {
                    background-color: inherit;
                }

                td.col-scores {
                   display: none;
                }

                .download-cert {
                    display: none;
                    span {
                        cursor: pointer;
                    }
                    @include for-size(lg){
                        display: flex;
                        color: #1890ff;
                        font-weight: bold;
                        margin: 0 auto;
                        margin-bottom: 24px;
                        margin-top: 16px;
                        max-width: 1200px;

                    }
                }

                #cloned-table {
                    background-color: transparent !important;
                    padding: 0;
                    border: 1px solid rgb(205, 207, 209);
                }
                #cloned-table *{
                    font-size: 13px;
                    overflow: hidden !important;
                    // td {
                    //     line-height: 1.5715 !important;
                    //     border-bottom: 2px solid #f0f0f0;
                    // }
                    // .ant-table-tbody > tr:last-child > td {
                    //     border: none !important;
                    // }
                    th, th * {
                        background-color: rgb(205, 207, 209);
                        font-size: 13px !important;
                        padding: 4px 0;
                        color: rgb(101, 101, 101);
                    }
                    th:nth-child(1) {
                        padding-left: 87px !important;
                    }
                    th:nth-child(2) {
                        padding-left: 16px !important;
                    }
                    td {
                        line-height: 1.5715 !important;
                        border-bottom: none !important;
                        font-weight: 600;
                    }
                    td.ant-table-cell.work-field::before {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 1px;
                        height: 95%;
                        background-color: rgba(0, 0, 0, 0.06);
                        transform: translateY(-50%);
                        transition: background-color 0.3s;
                        content: '';
                    }
                    .ant-table-thead > tr > th, .ant-table-tbody > tr > td,
                    .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
                        padding: 0 8px;
                    }
                    td.competence * {
                        color: #7E797F;
                        font-weight: 500 !important;
                    }
                    td.work-field * {
                        color: #686576;
                    }
                    .digital-profile-rowtb td.work-field .name{
                        font-size: 15px;
                    }
                    td.col-competences, td.work-field {
                        border-bottom: 2px solid #f0f0f0 !important;
                    }
                    tr.digital-profile-rowtb:last-child td.col-competences,
                    tr.digital-profile-rowtb:last-child td.work-field {
                        border: none !important;
                    }
                }

                .header-pdf {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    height: 87px;
                    .info-pdf {
                        flex-basis: 52%;
                        text-align: left;
                        padding: 10px;
                        color: #fff;
                        font-size: 18px;
                        background-color: rgb(31, 78, 120);
                        margin-bottom: 16px;
                        margin-top: 4px;
                    }
                    .header-right {
                        flex-basis: 30%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .col-1 {
                            img {
                                width: auto;
                                height: 63px;
                            }
                        }
                        .col-2, .col-2 > * {
                            text-align: justify;
                            flex: 1;
                            font-size: 11px;
                            margin-left: 16px;
                            font-weight: 600;
                        }
                    }
                }
                #footer-pdf {
                    display: flex;
                    justify-content: center;
                    height: 78px;
                    margin-top: 28px;
                    img {
                        margin: 0 3%;
                        width: auto;
                        height: 100%;
                    }
                }
            }
        }
    }
}