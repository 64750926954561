.test-result-page {
    padding: 0;
    display: flex;
    .test-result {
        flex: 1;
        display: flex;
        flex-direction: column;
        .title {
            font-size: 20px;
            font-weight: bold;
            padding: 10px;
            background-color: white;
            text-transform: uppercase;
        }
    
        .competence-list {
            .competence {
                padding: 16px 24px;
                background-color: #5B5B5F;
                color: #fff;
                font-weight: 500;
                margin-top: 24px;
    
                > *:not(:first-child) {
                    margin-top: 16px;
                }
    
                &__name {
                    font-size: 18px;
                }
    
                &__level {
                    .level-box {
                        display: flex;
                        flex-direction: row;
                        margin: -8px;
                        min-height: 44px;
    
                        .level-item {
                            margin: 8px;
                            text-align: center;
    
                            &.active {
                                .level-item__line {
                                    background-color: #2e9dea;
                                }
                            }
    
                            &__line {
                                width: 64px;
                                height: 6px;
                                background-color: #cdcfd1;
                            }
    
                            &__label {
                                font-size: 11px;
                            }
                        }
                    }
                }
    
                &__content {
                    font-size: 16px;
                    color: #2E9DEA;
                    font-style: italic;
                }
            }
        }
    
        .row {
            display: block;
            @include for-size(lg) {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: stretch;
                flex-wrap: wrap;
            }
            .col-1 {
                background-color: #fff;
                text-align: center;
                min-width: 297.22px;
                width: 100%;
                max-width: unset;
                padding-bottom: 24px;
                @include for-size(lg) {
                    padding-bottom: 0px;
                }
                .congratulation {
                    text-align: left;
                    width: calc(100% - 48px);
                    margin: 0 auto;
                    padding: 16px;
                    border-radius: 8px;
                    background-color: #ECFFF5;
                    border: 1px solid #95ccaf;
                }
        
                .comeback {
                    display: none;
                    padding: 8px;
                    justify-content: center;
                    align-items: stretch;
                    @include for-size(lg) {
                        display: flex;
                    }
                    .link {
                        flex: 1;
                        padding: 16px;
                        div {
        
                        }
                        img {
                            margin-bottom: 16px;
                            width: 100%;
                            height: 80px;
                            object-fit: contain;
                        }
                    }
                }
            }
        
            .col-2 {
                background: #3C3C3B;
                padding: 24px;
                width: 100%;
                max-width: unset;
                .title {
                    background-color: #ED2D16;
                    color: #fff;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }
    
        .row--mb {
            flex: 1;
            padding-top: 16px;
            align-items: stretch;
            display: flex;
            @include for-size(lg) {
                display: none;
            }
            background-color: #fff;
            text-align: center;
            .link {
                flex: 1;
                padding: 16px;
                div {
    
                }
                img {
                    margin-bottom: 16px;
                    width: 100%;
                    height: 80px;
                    object-fit: contain;
                }
            }
        }
    }
}