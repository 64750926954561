.test-page {
    padding: 0;
    display: flex;
    .test-page-container {
        flex: 1;
        > .ant-row {
            @include for-size(lg) {
                flex: 1;
                margin: 0;
                > .ant-col {
                    padding-right: 8px;
                }
                > .ant-col.question-section {
                    padding-left: 8px;
                    padding-right: 0;
                }
            }
        }
        > .ant-spin-nested-loading {
            min-height: 100%;
        }
    }

    .competence-section {
        background-color: #3c3c3b;
        padding: 15px;
        min-height: 100%;

        .ant-collapse {
            width: 100%;
        }

        .ant-list-item {
            border: none !important;
        }

        .progress-exam {
            .ant-progress-inner {
                background-color: #cdcfd1;
            }

            .ant-progress-text {
                color: white;
            }
        }

        .ant-collapse-header {
            background-color: #5B5B5F;
            border-radius: 0 !important;
            color: #fff;
        }

        .ant-collapse-content {
            color: #fff !important;
            background-color: #5B5B5F !important;
            border-top: none !important;
        }

        .ant-collapse-item {
            border: none;
        }

        .competence {
            > *:not(:first-child) {
                margin-top: 16px;
            }

            &__level {
                .level-box {
                    display: flex;
                    flex-direction: row;
                    margin: 8px 0;
                    width: calc(100% - 90px);
                    min-width: 137px;
                    .level-item {
                        margin-right: 2px;
                        text-align: center;
                        width: 33%;
                        &.active {
                            .level-item__line {
                                background-color: #2e9dea;
                            }
                        }

                        &__line {
                            height: 6px;
                            background-color: #cdcfd1;
                        }

                        &__label {
                            margin-top: 4px;
                            font-size: 8px;
                            white-space: nowrap;
                        }
                    }
                }
            }
            &__content {
                font-size: 16px;
            }
        }

        .ant-collapse-content-box {
            padding: 0
        }
    }

    .question-section {
        padding-top: 24px;
        padding-bottom: 24px;
        position: relative;

        .submit-exam-box {
            text-align: right;
            background-color: #3c3c3b;

            .btn-submit {
                color: white;
                background-color: #e5660e;
                border: 0;
            }
        }

        .question-info {
            background: none;
            border-color: #2e9dea;
            color: #1a773c;
            border-radius: 6px;
            margin-top: 1em;
            margin-bottom: 1em;
            font-size: 1em;
            border-style: solid;
            border-width: 1px;
            display: block;
            font-weight: 300;
            padding: 16px 20px;
            position: relative;
            width: calc(100% - 16px);
            margin: 0 auto;
            @include for-size(lg) {
                width: 100%;
            }
            legend {
                width: fit-content !important;
                position: absolute !important;
                top: -16px;
                left: -8px;
                background-color: rgb(240, 242, 245);
                margin: 0px;
                padding: 2px;
            }
        }

        .col-content {
            padding: 0 !important;
        }

        .btn-submit-answer {
            color: white;
            border-radius: 6;
            background-color: #2eadb2;
            border-color: #2eadb2;
        }

        .step-by-step-images {
            .ant-spin-nested-loading > div > .ant-spin {
                position: absolute !important;
            }
        }
    }
}

// .test-page {
//     padding: 0;
//     @include for-size(md){
//         padding: 0 24px;
//     }
//     display: flex;
//     flex-direction: column;
//     max-width: 1336px;
//     width: 100%;
//     margin: 0 auto;

//     .sider{
//         position: fixed;
//         @include for-size(md){
//             position: relative;
//             width: 28vw !important;
//             min-height: 36vw;
//             height: auto;
//             max-width: 350px !important;
//             min-width: 260px !important;
//             top: 0;
//         }
//         left: 0;
//         top: $heighAppHeaderMobile;
//         z-index: 99;
//         height: calc(100% - #{$heighAppHeaderMobile});
//         flex: unset !important;
//         width: 80vw !important;
//         max-width: unset !important;
//         min-width: 80vw !important;
//         background-color: rgb(53, 53, 53) !important;

//         .menu-title{
//             color: white;
//             font-size: 1.6em;
//             font-weight: 600;
//             text-transform: uppercase;
//             border-bottom: 1px solid #ccc;
//             padding: 12px 0;
//             div{
//                 @include for-size(md){
//                     font-size: 1.1em;
//                     margin-right: 0 !important;
//                 }
//             }
//         }

//         .ant-layout-sider-children {
//             display: flex;
//             flex-direction: column;
//         }

//         .menu{
//             @include for-size(md){
//                 margin-top: 0;
//                 height: auto;
//             }
//             // height: 83vh;
//             flex: 1;
//             overflow-y: auto;
//             background-color: inherit !important;
//             .menu-item{
//                 height: auto;
//                 min-height: 64px;
//                 align-items: start;
//                 line-height: 1.6;
//                 white-space: normal;
//                 padding: 1em;
//                 font-size: 1.1rem;
//                 margin: 0;
//                 border-bottom: 1px solid #ccc
//             }

//             .ant-menu-title-content{
//                 color: white;
//                 font-weight: 450;
//             }
//             .ant-menu-item-selected{
//                 background-color: rgb(82, 80, 80);
//                 .ant-menu-title-content{
                    
//                 }
//             }
//         }

//         .ant-layout-sider-zero-width-trigger{
//             left: 0 !important;
//             top: 0;
//             z-index: 9;
//             opacity: 0;
//             transition: all 0.1s;
//             color: white;
//             background-color: rgb(0, 18, 78);
//         }

//         .close{
//             @include for-size(md){
//                 display: none;
//             }
//             position: absolute;
//             right: 8px;
//             top: 8px;
//             z-index: 999;
//             color: white;
//             font-size: 1.3rem;
//             font-weight: bold;
//         }

//     }

//     .sider.ant-layout-sider-collapsed{
//         left: -81vw;
//         .ant-layout-sider-zero-width-trigger{
//             opacity: 1;
//             left: 81vw !important;
//         }
//     }

//     .layer{
//         @include for-size(md){
//             display: none;
//         }
//         position: absolute;
//         top: 0;
//         left: 0;
//         bottom: 0;
//         right: 0;
//         background-color: rgba(0, 0, 0, 0.226);
//         z-index: 1;
//     }
    
//     .layer-hide{
//         display: none;
//     }

//     main{
//         padding: 0;
//         @include for-size(md){
//             padding: 1.6vw 2.1vw;
//         }
//         background-color: #fff;
//         .list-news, .detail-news{
//             padding: 5px;
//             display: flex;
//             flex-direction: column;
//             justify-content: space-between;
//             position: relative;
//             background-color: transparent;
//             height: 100%;
//             @include for-size(md){
//                 height: auto;
//                 padding: 0px;
//             }


//             .ant-spin-nested-loading {
//                 position: unset;
//             }

//             .list-item{
//                 display: block;
//                 padding: 0;
//                 margin: 16px 0;
//                 overflow: hidden;
//                 border-bottom: none;
//                 @include for-size(sm){
//                     margin: 0;
//                     margin-bottom: 1.9vw;
//                     overflow: hidden;
//                 }
//                 @include for-size(md){
//                     padding: 16px;
//                     padding: 0;
//                 }

//                 .new-avatar{
//                     height: auto;
//                     width: 30vw;
//                     height: auto;
//                     margin: 0 2vw 0 0;
//                     max-width: 170px;
//                     max-height: 170px;
//                     float: left;
//                     min-height: 50px;
//                 }

//                 .item-meta {
//                     color: rgba(0, 0, 0, 0.9);
//                     font-size: 1.1rem;
//                     font-weight: bold;
//                     margin: 0 auto;
//                     @include for-size(md){
//                         min-width: unset;
//                         width: 100%;
//                     }
//                 }

//                 .new-content{
//                     max-height: 113px;
//                     color: rgba(0, 0, 0, 0.9);

//                     iframe, img{
//                         width: 100%;
//                     }
//                     iframe{
//                         height: 100%;
//                         max-height: 20%;
//                     }
//                 }
//             }
//             .list-item:nth-child(1){
//                 margin-top: 0;
//             }

//             .item-content{
//                 padding: 0;
//                 line-height: 1.5;
//                 @include for-size(md){
//                     padding: 0;
//                 }
//             }
//             .new-content{
//                 iframe, img{
//                     width: 100%;
//                     max-width: 900px;
//                     max-height: 500px;
//                     display: block;
//                 }
//                 iframe{
//                     height: 65vh;
//                     @include for-size(md){

//                     }
//                     width: 100%;
//                 }
//             }

//             .ant-list-pagination{
//                 position: absolute;
//                 right: 1rem;
//                 bottom: 1rem;
//             }
//         }
//     }
//     .ant-tooltip{
//         display: none;
//     }


//     // span.anticon.anticon-check, span.anticon.anticon-check svg
//     // {
//     //     color:red;
//     //     fill:red;
//     // }
// }