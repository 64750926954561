.login-page {
    display: flex;
}

.login-container {
    max-width: 460px;
    width: 100%;
    margin: auto;
    padding: 0 16px;

    .page-title {
        text-align: center;
    }

    .login-form-button {
        width: 100%;
    }

    .ant-form-item-explain {
        font-size: 13px;
        margin: 4px 0;
    }
}